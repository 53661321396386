.adoption-container {
    background: #fafafa;
    padding: 70px 0;

    @media (max-width: 950px) {
        padding: 0;
    }
}

.adoption-box {
    max-width: 940px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
        padding: 20px 25px;
    }

    @media (max-width: 950px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.img-adoption {
    height: 500px;

    @media (max-width: 600px) {
        height: 300px;
    }
}

.adoption-text {
    margin-top: 85px; 
    text-align: left; 

    @media (max-width: 950px) {
        margin: 35px 0 75px;
        text-align: center;
    }  
}

.text {
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-weight: normal;
    text-align: left; 
    margin: 35px 0 50px;
    line-height: 23px;
}

.adoption-button {
    background: transparent;
    border: 2px solid #f1cdb3;
    border-radius: 30px;
    font-size: 17px;
    font-family: "Georgia";
    font-weight: normal;
    color: #292929;
    padding: 15px 40px;

    &:hover {
    cursor: pointer;
    background-color: #f1cdb3;
    }
}

.temporary-adoption-box {
    max-width: 830px;
    margin: 0 auto;
    padding: 60px 0;
    text-align: center;

    @media (max-width: 1200px) {
        padding: 60px 35px;
    }

    img {
        height: 270px;

        @media (max-width: 600px) {
            height: 160px;
        }
    }
}

.volunteer-container {
    background: #fafafa;
    padding: 70px 0 70px;
}

.be-volunteer-box{
    max-width: 830px;
    margin: 0 auto;
    text-align: center;
    padding: 0;

    @media (max-width: 950px) {
        padding: 0 35px;
    }
}

.volunteer-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;

    @media (max-width: 950px) {
        margin: 50px auto 0;
        max-width: 470px;
        justify-content: center;
        align-items: center;
    }
}

.volunteer-item {
    text-align: center;

    @media (max-width: 950px) {
        flex-basis: 135px;
        padding: 25px;
    }

    img {
        height: 55px;
        margin-bottom: 20px;
    }

    div {
        font-size: 20px;
        font-family: "Georgia";
        color: rgb(84, 84, 84);
    }
}

.be-volunteer {
    text-align: left;
    margin-top: 70px;

    div {
        font-size: 18px;
        font-family: "Georgia";
        font-weight: 550;
        color: rgb(84, 84, 84);
        margin: 20px 0 10px;
    }

    p {
        font-size: 15px;
        font-family: "Arial";
        color: rgb(76, 76, 76);
        font-weight: normal;
        margin: 0;
        line-height: 23px;
    }

    h4 {
        text-align: center;
        padding: 35px 0 20px;
        font-weight: normal;
        font-size: 25px;
        font-family: "Georgia";
        color: rgb(84, 84, 84);
        margin: 0;
    }
}

.time {
    text-align: center;
}

.become-volunteer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0  75px;

    @media (max-width: 600px) {
        flex-direction: column;
        padding: 0;
        gap: 20px;
    }

    p {
        font-family: 'Georgia';
        font-size: 17px;
    }
}