.root {
    display: flex;
    justify-content: center;
    
    li{
        display: inline-block;
        align-items: center;
        margin-top: 30px;
    
        a {
            background: #f6f6f6;
            border: 3px solid #f1cdb3;
            border-radius: 50%;
            margin: 0 5px;
            padding: 10px 16px;
            font-size: 20px;
            font-family: "Times New Roman";
            color: rgb(41, 41, 41);

            &:hover {
                background: #f1cdb3;
                cursor: pointer;
            }
        }
    }

    :global {
        .selected{
            a {
                background-color:#f1cdb3;
            }
        }
    }
}