.contact-container {
    background: #fafafa;
    padding: 75px 0 75px;
}

.contact-box {
    max-width: 940px;
    margin: 0 auto;

    @media (max-width: 1200px) {
        padding: 0 25px;
    }
}

.contact-items {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 250px;
    justify-content: space-between;

    @media (max-width: 950px) {
        max-width: 500px;
        margin: 0 auto;
    }

    @media (max-width: 600px) {
        justify-content: left;
        flex-direction: column;
    }
}

.contact-item {
    display: flex;
    justify-content: center;

    svg {
        margin: 0 10px 10px 0;
        height: 30px;
        padding: 0;
        
        path {
            fill: rgb(241, 205, 179);
        }               
    }
     
    p {
        font-size: 15px;
        font-family: "Arial";
        color: rgb(76, 76, 76);
        font-weight: normal;
        margin: 5px 0 0;
    }
}

.phone{
    @media (max-width: 950px) {
        padding-right: 45px;
    }
}

.mail {
    @media (max-width: 600px) {
        padding-right: 45px;
    }
}

.form-box {
    padding: 65px 0 75px;
    max-width: 500px;
    margin: 0 auto;
}

.feedback {
    display: flex;
    flex-direction: column;  
}

textarea {
    border: 1px solid rgb(76, 76, 76);
    border-radius: 10px;
    &:focus {
        outline: none;
    }
}

.user-info {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        display: grid;
        gap: 25px;
    }
}

.user-field{
    padding: 10px 28px;
    margin: 0;
    height: 50px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-size: 15px;
    font-weight: normal;
}

.email-field {
    width: 100%;
    padding: 15px 28px;
    margin-top: 25px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-size: 15px;
    font-weight: normal;
}

.message-field {
    margin-top: 25px;
    padding: 10px 10px;
    width: 100%;
    height: 160px;
    resize: none;
    font-family: "Arial";
    font-size: 15px;
    color: rgb(76, 76, 76);
    font-weight: normal;
}

.message-button {
    width: 100%;
    padding: 15px 0;
    margin-top: 20px;
    border: 1px solid #f1cdb3;
    background: #f1cdb3;
    border-radius: 10px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-size: 15px;

    &:hover {
        cursor: pointer;
        border: 1px solid rgb(76, 76, 76);
    }
}

.map-container {
    width: 100%;
    height: 400px;
    border: 1px solid rgb(76, 76, 76);
    border-radius: 10px;

    @media (max-width: 600px) {
        height: 550px;
    }
}
