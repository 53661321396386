.home-container {
    padding: 0;
    background: linear-gradient(90deg, rgba(76,62,54,1) 0%, #1a1a1c 100%);

    @media (max-width: 1200px) {
        padding: 0 25px;
    }
}

.home-box {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; 

    @media (max-width: 950px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.img-home {
    height: 600px;

    @media (max-width: 950px) {
        margin-top: 30px;
    }

    @media (max-width: 600px) {
       height: 320px;
    }
}

.home-text {
    padding: 0;
    margin: 0 auto;

    h2 {
        margin: 150px auto 0;
        font-size: 44px;
        font-family: "Georgia";
        font-weight: 300;
        color: rgb(255, 255, 255);
        text-align: left;
    }

    p {
        font-size: 15px;
        font-family: "Arial";
        color: rgb(205, 205, 205);
        margin: 45px auto 65px;
        line-height: 24px;
    }
}

.home-header-button {
    border: 2px solid #f1cdb3;
    border-radius: 30px;
    font-size: 17px;
    font-family: "Georgia";
    color: rgb(255, 255, 255);
    margin: 0 auto;
    padding: 15px 45px;

    &:hover {
    cursor: pointer;
    background-color: #f1cdb3;
    color: #292929;
    }
}

.home-about-box {
    background: #fafafa;

    @media (max-width: 1200px) {
        padding: 0 25px;
    }
}

.home-about {
    max-width: 830px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 95px 0;

    @media (max-width: 950px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.home-about-items{
    margin-top: 40px;

    @media (max-width: 950px) {
    text-align: center;
    }
}

.home-about-text{
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    text-align: left;
    font-weight: normal;
    margin-top: 35px;
}

.home-pets-box {
    max-width: 1170px;
    margin: 65px auto 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media (max-width: 1200px) {
        padding: 0 25px;
    }
}

.home-pets {
    max-width: 910px;
    margin: 65px auto 25px;
    text-align: left;

    @media (max-width: 1200px) {
        max-width: 700px;
    }

    @media (max-width: 950px) {
        max-width: 650px;
    }

    @media (max-width: 600px) {
        max-width: 300px;
    }
}

.button-to-pets {
    margin: 0 auto;
    padding: 18px 50px;
    background: #f6f6f6;
    border: 2px solid #f1cdb3;
    border-radius: 30px;
    font-size: 17px;
    font-family: "Georgia";
    color: rgb(41, 41, 41);  
    
    &:hover {
        cursor: pointer;
        background: #f1cdb3;
    }
}

.home-help-box {
    background: #fafafa;
    margin: 0 auto;
    text-align: center;
    padding-top: 75px;

}

.help-items {
    max-width: 1030px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 75px auto 0;

    @media (max-width: 1000px) {
       max-width: 700px;
    }
}

.help-item {
    flex-basis: 200px;
    margin-bottom: 84px;
}

.img-help {
    height: 55px;
    margin-bottom: 30px;
}

.help-item-text {
    font-size: 20px;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
}