.about-history-container{
    background: #fafafa;
    text-align: center;
    padding: 75px 0 75px;

    @media (max-width: 950px) {
        padding: 50px 25px 50px;
    }
}

.about-history-box {
    max-width: 940px;
    margin: 0 auto;
}

.history-text {
    text-align: left;
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-weight: normal;
    margin: 35px 0 20px;
    line-height: 23px;
}

.counters{
    display: flex;
    justify-content: space-around;
}

.counter {
    text-align: center;
}

.counter-number{
    font-size: 50px;
    font-family: "Arial";
    color: #f1cdb3;
    font-weight: normal;
    margin-bottom: 15px;
}

.counter-text {
    font-size: 25px;
    font-family: "Georgia";
    color:  rgb(84, 84, 84);
    font-weight: normal;
    margin: 0;
}

.about-activity-container {
    max-width: 940px;
    margin: 0 auto;
    padding: 75px 0 75px;
    text-align: center;

    @media (max-width: 950px) {
        padding: 50px 25px 50px;
    }
}

.activity-text {
    margin-top: 35px  ;
    text-align: left;
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    font-weight: normal;
    line-height: 23px;
}

.about-values-container {
    background: #fafafa;
    margin: 0 auto;
    text-align: center;
    padding-top: 75px;
    padding-bottom: 15px;
}

.values-items{
    max-width: 940px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 65px auto 0;
}

.value-item {
    flex-basis: 200px;
    margin-bottom: 65px;

    img  {
        height: 65px;
        margin: 0 auto;
    }

    p {
        font-size: 20px;
        font-family: "Georgia";
        color: rgb(84, 84, 84);
    }
}