.donation-box {
    max-width: 1140px;
    display: flex;
    margin: 0 auto;
    padding-bottom: 86px;
    padding-top: 73px;

    @media (max-width: 1200px) {
        padding: 73px 25px 86px;
    }

    @media (max-width: 950px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}

.img-addition {
    margin-right: 62px;
    height: 272px;
    margin-top: 45px;

    @media (max-width: 1200px) {
        margin: 0 0 45px;
    }

    @media (max-width: 600px) {
        height: 160px;
    }
}

.addition-items {
    padding-left: 50px;

    @media (max-width: 950px) {
       padding: 0;
    }
}

.bank {
    font-size: 15px;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
    margin-top: 36px;
}

.card {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    background: #f1cdb3;
    border-radius: 10px;
}

.img-card {
    height: 24px;
    margin-right: 7px;
}

.card-number {
    font-size: 20px;
    font-family: "Times New Roman";
    color: rgb(41, 41, 41);
    margin: 0;
    padding: 0;
}

.addition-text {
    font-size: 12px;
    font-family: "Arial";
    color: rgb(178, 178, 178);
    font-style: italic;
    margin-top: 34px;
}