* {
    box-sizing: border-box;
}

body {
    max-width: 1920px;
    margin: 0;
    padding: 0;
    background: #f6f6f6;
}

.Pagination_root__jgudo {
    padding: 0;
}

.page-top {
    text-align: center;
    padding: 70px 0;
}

a {
    text-decoration: none;
}

.sortby-box{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: 600px) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding-bottom: 30px;
    }
}

.category {
    display: flex;
    list-style: none;
    margin: 0;
    padding-left: 5px;

    li {
        font-size: 17px;
        font-family: "Georgia";
        color: rgb(84, 84, 84);
        margin: 0 7px 20px ;
        padding: 8px 30px;
        border: 2px solid #f1cdb3;
        border-radius: 25px;
        background-color: #fafafa;
    
        &:hover {
            background-color: #f1cdb3;
            cursor: pointer;
        } 
    }

    .active {
        background-color: #f1cdb3;
    }
}

.pets-container {
    max-width: 1170px;
    margin: 0 auto 65px;

    @media (max-width: 1200px) {
        padding: 0 50px;
    }

    @media (max-width: 600px) {
        padding: 0;
    }
}

.sort {
    position: relative;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
    padding-right: 20px;
    margin-top: 5px;
}

.sort-container {
    display: flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }

    .sort-text {
        margin-right: 8px;
    }

    .sort-chosen {
        font-weight: bold;
        color: #eabc9a;
        cursor: pointer;
    }
}

.sort-popup {
    position: absolute;
    z-index: 10;
    right: 0;
    margin-top: 15px;
    background:#fafafa;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0;

    ul {
      overflow: hidden;
      list-style: none;
      padding: 0;
      li {
        padding: 12px 30px;
        cursor: pointer;

        &:hover {
          background: #f1cdb3;
        }
      }
    }
}

h3 {
    font-weight: normal;
    font-size: 35px;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
    margin: 0 auto;

    @media (max-width: 1200px) {
        padding: 20px 25px;
    }
}

.pets-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.pets-item {
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
    padding-bottom: 35px;
    margin: 0 auto 35px;
    border-radius: 10px;
    max-width: 250px;
    position: relative;
}

.main-img {
    height: 250px;
    width: 250px;
}

 .button {
    padding: 20px 50px;
    border: 2px solid #f1cdb3;
    border-radius: 30px;
    background-color: #fafafa;
    font-size: 17px;
    font-family: "Georgia";
    color: rgb(41, 41, 41);
    text-align: center;
    margin: 0 auto;

    &:hover {
        background-color: #f1cdb3;
        cursor: pointer;
    } 
}

.name {
    font-size: 20px;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
    text-align: center;
    margin: 30px auto 20px;
    padding: 0;
}

.count_page:hover {
    cursor: pointer;
    background: #f1cdb3;
}

.previous{
    a {
        display: none;
    }
}

.next {
    a {
        display: none;
    }
}


.footer-container {
    // margin: 0;
    // padding: 0;
    background: rgb(76,62,54);
    background: linear-gradient(90deg, rgba(76,62,54,1) 49%, rgba(25,25,27,1) 100%);

    @media (max-width: 1200px) {
        padding: 0 25px;
    }
}

.footer-inner {
    max-width: 1170px;
    margin: 0 auto;
}

.footer-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 950px) {
        justify-content: center;
        align-items: center;
    }
}

.footer-item {
    display: grid;
    flex-basis: 300px;
    padding: 0;
    margin-top: 83px;
    padding-bottom: 20px;

    @media (max-width: 950px) {
        gap: 35px;
        padding: 0;
    }
}

.footer-text {
    font-size: 35px;
    font-family: "Georgia";
    font-weight: normal;
    color: rgb(255, 255, 255);
    text-align: left;
    padding: 0;
    margin: 0;
}

.contact {
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-family: "Times New Roman";
    color: rgb(241, 205, 179);
    text-align: left;

    svg {
        height: 30px;
        padding: 0;
        margin-right: 25px;
            
        path {
            fill: rgb(241, 205, 179);
        }    
    }
}

.contact-text {
    margin: 0;
    padding: 0;
}

//slick slider 
.slick-list {
    padding: 8px 0px;
  }
  
  .slick-slide {
    &>div {
      margin: 0px 6px;
    }
  }

//form
.input-field-wrapper {
    position: relative;
    text-align: left;
    
}

.type-error {
    position: absolute;
    margin: 0; 
    font-size: 12px;
    font-family: "Arial";
    color: rgb(232, 124, 10);

    p {
        margin: 3px 0  0;
    }
}

input {
    border: 1px solid rgb(76, 76, 76);
    border-radius: 10px;
    &:focus {
        outline: none;
    }
}

//modal
.modal-wrapper{
    position:fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}

.back-drop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(76, 76, 76);
    opacity: 15%;
}