.modal-box {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 950px) {
        display: block;
        max-height: 85vh;
        overflow-y: auto;
    }
}

.pet-modal {
    max-width: 942px;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fafafa;
    border-radius: 9px;
    box-shadow: 0px 2px 30.1px 4.9px rgba(13, 13, 13, 0.04);
    transition: 0.5s;

    @media (max-width: 950px) {
        max-width: fit-content;
        padding-bottom: 5px;
        margin-top: 15px;
    }
}

.pet {
    height: 430px;

    @media (max-width: 1200px) {
        height: 360px;
    }

    @media (max-width: 950px) {
        height: 400px;
    }

    @media (max-width: 600px) {
        height: 270px;
    }
}

.modal-text  {
    padding: 0 40px;
    flex-grow: 1;

    ul {
        margin-left: 15px;
        padding: 0;
        margin-bottom: 60px;
        color: rgb(84, 84, 84);

        @media (max-width: 600px) {
            margin-bottom: 20px;
        }
    }
    
    li{
        margin-bottom: 15px;
        color: #f1cdb3;
    }

    @media (max-width: 600px) {
       padding: 0 15px;
    }
}

.pet-name {
    font-size: 35px;
    font-weight: normal;
    font-family: "Georgia";
    color: rgb(84, 84, 84);
    margin-top: 60px;
    margin-bottom: 0;
    padding: 0;

    @media (max-width: 1050px) {
       margin-top: 25px;
    }

    @media (max-width: 600px) {
        margin-top: 0;
    }
}

.type {
    font-size: 20px;
    font-weight: normal;
    font-family: "Georgia";
    color: rgb(76, 76, 76);
    margin-top:15px;
    margin-bottom: 32px;

    @media (max-width: 600px) {
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

.description {
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    margin-bottom: 30px;
    line-height: 20px;

    @media (max-width: 600px) {
        margin-bottom: 0;
    }
} 

.info {
    font-size: 17px;
    font-family: "Georgia";
    font-weight: 500;
    color: rgb(76, 76, 76);
}

.info-text {
    font-size: 15px;
    font-family: "Arial";
    color: rgb(76, 76, 76);
    margin-bottom: 30px;
} 

.close {
    position: absolute;
    right: -40px;
    top: -40px;
    background:#fafafa;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border:2px solid #f1cdb3;
    border-radius: 50%;
    text-decoration: none;
    z-index: 10;

    &:hover {
        cursor: pointer;
        background: #f1cdb3;
        text-decoration: none;
        }  
}

.x {
    margin: 0;
    height: 20px;
    width: 20px;

    path{
        fill: rgb(76, 76, 76);
    }
}