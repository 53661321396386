//dark header
.dark {
    padding-bottom: 30px;
    margin: 0;
    background: linear-gradient(90deg, rgba(76,62,54,1) 0%, #1a1a1c 100%);  
}

.dark__inner {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
        padding: 0 25px;
    }

    @media (max-width: 890px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    a {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        
        font-size: 15pt;
        font-family: "Arial";
        color: rgb(205, 205, 205);
    
        &:hover {
            text-decoration: none;
        }

        p {
            font-size: 13px;
            font-family: "Arial";
            color: rgb(255, 255, 255);
            margin: 0;
        }
         
        h3 {
            margin-top: 32px;
            font-weight: normal;
            font-size: 32px;
            font-family: "Georgia";
            color: #f1cdb3; 
            margin-bottom: 10px;
            padding: 0; 
        }    
    }
    
    ul {
        display: flex;
        justify-content: space-between ;
        list-style: none;
        margin-top: 50px;
        padding: 0;

        @media (max-width: 700px) {
            flex-direction: column;
            margin-top: 25px;

        }

        a {
            padding-left: 40px;

            @media (max-width: 890px) {
                padding: 0 15px;
            }

            @media (max-width: 700px) {
                margin-top: 25px;
            }

            &:hover{
                text-decoration:underline 7px #f1cdb3 ;
                color: rgb(255, 255, 255)
            }
        }    
    }
}


//light header

.light {
    padding-bottom: 30px;
    margin: 0;
    background: #fafafa;   
}

.light__inner {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 1200px) {
        padding: 0 25px;
    }

    @media (max-width: 890px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    a {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        
        font-size: 15pt;
        font-family: "Arial";
        color: rgb(84, 84, 84);
    
        &:hover {
            text-decoration: none;
        }

        p {
            font-size: 13px;
            font-family: "Arial";
            color: rgb(41, 41, 41);
            margin: 0;
        }
         
        h3 {
            margin-top: 32px;
            font-weight: normal;
            font-size: 32px;
            font-family: "Georgia";
            color: rgb(84, 84, 84); 
            margin-bottom: 10px;
            padding: 0; 
        }    
    }
    
    ul {
        display: flex;
        justify-content: space-between ;
        list-style: none;
        margin-top: 50px;
        padding: 0;

        @media (max-width: 700px) {
            flex-direction: column;
            margin-top: 25px;

        }

        a {
            padding-left: 40px;

            @media (max-width: 890px) {
                padding: 0 15px;
            }

            @media (max-width: 700px) {
                margin-top: 25px;
            }

            &:hover{
                text-decoration:underline 7px #f1cdb3 ;
                color: rgb(41, 41, 41);
            }
        }    
    }
}