.v-modal {
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fafafa;
    border-radius: 9px;
    box-shadow: 0px 2px 30.1px 4.9px rgba(13, 13, 13, 0.04);
    transition: 0.5s;
}

.volunteer-box {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
    padding: 35px 55px 35px;
    height: 85vh;
    overflow-y: auto;

    @media (max-width: 600px) {
        padding: 20px 10px;
    }
}

.volunteer-form{
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.form-field {
    padding: 12px 100px 12px 15px;
    position: relative;
    font-size: 15px;
    font-family: "Arial";
    color: #292929;

    @media (max-width: 600px) {
        padding: 12px 70px 12px 15px;
    }
}

.checkboxes {
    background: #fdfefc;
    text-align: left;
    border: 1px solid rgb(76, 76, 76);
    border-radius: 10px;
    padding: 30px 35px 15px;
}

.question {
    margin-bottom: 25px;
    font-size: 19px;
}

.optionName {
    font-size: 17px;
    font-family: "Arial";
    color: rgb(84, 84, 84);
    padding-left: 10px;
}    

.btn-submit {
    background: transparent;
    border: 2px solid #f1cdb3;
    border-radius: 30px;
    font-size: 17px;
    font-family: "Georgia";
    color: #292929;
    padding: 17px 50px;
    margin-top: 25px;

    &:hover {
    cursor: pointer;
    background-color: #f1cdb3;
    }
}


